// Angular imports
import { Component, ElementRef, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { from } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

// In-App Services
import { VdsTitleService } from './service/vds-title.service';
import { SessionService } from './service/session.service';
import { RedirectService } from './service/redirect.service';
import { AuthService } from './service/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'src/config/constants';

/*
 *  @author     @version    @date           @description
 *  HSenevir    01          Jun 26, 2023    AFLL-18373 Implemented Ipify parameters for controlling request timeouts
 *  HSenevir    02          Sep 27, 2023    AFLL-18353 Updated Prod www redirection to preserve query params including path
 *  HSenevir    03          Feb 10, 2023    AFLL-20737 Added isAnz variable to maintain if hostname is an ANZ one
 *  PPareek     04          Apr 23, 2024    AFLL-21289 - VPP & Vi | User Unable to Login via https://velys.jnj/ (without www)
 *  HSenevir    05          Sep 16, 2024    AFLL-22021 - Updated regex pattern to remove anz logic and added support for AU and NZ
 */

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private documentTitleService: VdsTitleService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private idle: Idle,
    private sessionService: SessionService,
    private redirectService: RedirectService,
    private keepalive: Keepalive,
    private authService: AuthService,
    private translate: TranslateService,
    private element: ElementRef
  ) {
    idle.setIdle(1);
    // sets a timeout period of 900 seconds
    idle.setTimeout(900);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document.
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onTimeout.subscribe(() => {
      const userSession = this.sessionService.getUserSession();
      if (!userSession) {
        return;
      }

      const inactivity = true;
      this.authService.removeUserData();
      this.router.navigate([this.redirectService.urls.login], {queryParams: {inactivity}})
        .then(() => {
          window.location.reload();
        });
    });
    this.keepalive.interval(15);

    if (this.sessionService.getUserSession()) {
      this.reset();
    }

    this.authService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch();
      } else {
        idle.stop();
      }
    });

    const hostname = location.host;

    const ukRegex = new RegExp('(-europe-|uk\\.)', 'g');
    const auRegex = new RegExp('au[-.]', 'g');
    const nzRegex = new RegExp('nz[-.]', 'g');

    const isAuHostname = hostname.match(auRegex) != null;
    const isNzHostname = hostname.match(nzRegex) != null;

    /*
    * This logic can be used for non-logged in scenarios.
    * This section defines all country related hostname detection.
    * Default US is considered as hostname for any country if condition
    * */
    AppConstants.isANZRegionHostname = isAuHostname || isNzHostname;
    AppConstants.isUKHostname = hostname.match(ukRegex) != null;
    AppConstants.isAUHostname = isAuHostname;
    AppConstants.isNZHostname = isNzHostname;
  }

  public ngOnInit(): void {
    const region = this.element.nativeElement.getAttribute('region');
    const ipifyApiEnabled = this.element.nativeElement.getAttribute('ipify-api-enabled');
    const ipifyApiMaxTimeout = this.element.nativeElement.getAttribute('ipify-api-max-timeout');

    if (ipifyApiMaxTimeout) {
      AppConstants.ipifyApiMaxTimeout = ipifyApiMaxTimeout;
    }

    if (ipifyApiEnabled) {
      AppConstants.ipifyApiEnabled = ipifyApiEnabled.toLowerCase() === 'true';
    }

    if (region) {
      AppConstants.region = region;
    }

    this.authService.getPublicIpAddress();

    this.translate.use(AppConstants.languageFile);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      switchMap(route => {
        const childrenRoutes = [];
        childrenRoutes.push(route);
        while (route.firstChild) {
          route = route.firstChild;
          childrenRoutes.push(route);
        }
        return from(childrenRoutes);
      }),
      filter(route => route.outlet === 'primary'),
      switchMap(route => route.data),
      filter(routeData => true === !!routeData['pageTitle'])
    ).subscribe((routeData) => {
      const pageTitle = (routeData && routeData['pageTitle'] ? routeData['pageTitle'] : '');
      this.documentTitleService.setDocumentTitle(pageTitle);
    });
  }

  reset(): void {
    this.idle.watch();
  }
}
