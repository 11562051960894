<section class="large-doc">
    <h1 class="title-section" *ngIf="!isUsedInAcceptance">
        Privacy Policy
    </h1>
    <div>
        <p>
            <b> Medical Device Business Services, Inc.</b>("We" or "Us") cares about your privacy and wants you to be
            familiar with how we collect, use, and disclose information. This Privacy Policy describes our practices in
            connection with information that we or our service providers collect through the VELYS&trade; Patient Path -
            HCPs
            portal (hereinafter the “<b>Service</b>”) operated and controlled by us from which you are accessing this
            Privacy Policy. By providing personal information to us or by using the Service, you acknowledge that you
            have read and understand this Privacy Policy.
        </p>

        <p class="sub-title">USE BY MINORS </p>
        <p>
            The Service is not directed to individuals under the age of <b>18</b>, and we request that these individuals
            not provide personal information through the Service.
        </p>

        <p class="sub-title">INFORMATION COLLECTION</p>
        <p>
            We will ask you to submit personal information in order for you to use the Services. You will be informed
            what information is required and what information is optional.
            <br /><br />
            We will combine the information you submit with other information we have collected from you, whether on- or
            offline. We may also combine it with information we receive about you from other sources, such as other
            Johnson & Johnson affiliates, publicly available information sources (including information from your
            publicly available social media profiles), and other third-party information providers. We may request
            personal or sensitive information for technical maintenance, service, or trouble shooting purposes.
            <br /><br /><u>If you submit any personal information relating to another individual to us, you represent
                that you have the authority to do so and to permit us to use the information in accordance with this
                Privacy Policy. </u>
        </p>

        <p class="sub-title ">SENSITIVE INFORMATION </p>
        <p>
            Unless we specifically request or invite you to do so, we ask that you not send us, and you not disclose,
            any sensitive personal information (e.g., Social Security numbers, information related to racial or ethnic
            origin, political opinions, religion or philosophical beliefs, health, sex life or sexual orientation,
            criminal background, or trade union membership, or biometric or genetic data for the purpose of uniquely
            identifying an individual) on or through the Service or otherwise to us. 

        </p>

        <p class="sub-title ">AUTOMATIC INFORMATION COLLECTION AND USE </p>
        <p>
            We and our service providers may automatically collect and use information in the following ways as you
            navigate around the Service:
        </p>

        <div class="Content-to-right">
            <p>
                <strong>Through your browser:</strong>
                Certain information is collected by most browsers, such as your Media Access Control (MAC) address,
                computer type (Windows or Mac), screen resolution, operating system name and version, and Internet
                browser type and version. We may collect similar information, such as your device type and identifier,
                if you access the Service through a mobile device. We use this information to ensure that the Service
                functions properly.
            </p>
            <p>
                <strong>Using cookies:</strong>
                Cookies are pieces of information stored directly on the computer you are using. Cookies allow us to
                collect information such as browser type, time spent on the Service, pages visited, and language
                preferences. We and our service providers use the information for security purposes, to facilitate
                navigation, display information more effectively, and to personalize your experience while using the
                Service. We also use cookies to recognize your computer or device, which makes your use of the Service
                easier, such as to remember what is in your shopping cart. In addition, we use cookies to gather
                statistical information about Service usage in order to continually improve its design and
                functionality, understand how individuals use it, and to assist us with resolving questions regarding
                it. Cookies further allow us to select which of our advertisements or offers are most likely to appeal
                to you and display them while you are on the Service. We may also use cookies in online advertising to
                track consumer responses to our advertisements. <br />
            </p>
            <p>
                You can refuse to accept these cookies by following your browser’s instructions; however, if you do not
                accept them, you may experience some inconvenience in your use of the Service. You may also not receive
                advertising or other offers from us that are relevant to your interests and needs. To learn more about
                cookies, please visit
                <a target="_blank" href="http://www.allaboutcookies.org/" class="link"
                    data-dom-id="http://www.allaboutcookies.org" data-event-group="footer">
                    http://www.allaboutcookies.org
                </a>.
            </p>
            <p>
                <strong>Using Flash cookies:</strong>
                Our use of Adobe Flash technology (including Flash Local Stored Objects (“Flash LSOs”)) allows us to,
                among other things, serve you with more tailored information, facilitate your ongoing access to and use
                of the Service, and collect and store information about your use of the Service.  If you do not want
                Flash LSOs stored on your computer, you can adjust the settings of your Flash player to block Flash LSO
                storage using the tools contained in the
                <a target="_blank"
                    href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
                    class="link"
                    data-dom-id="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
                    data-event-group="footer">
                    Website Storage Settings Panel
                </a>.
                You can also control Flash LSOs by going to the
                <a target="_blank"
                    href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
                    class="link"
                    data-dom-id="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
                    data-event-group="footer">
                    Global Storage Settings Panel
                </a>
                and following the instructions. Please note that setting the Flash Player to restrict or limit
                acceptance of Flash LSOs may reduce or impede the functionality of some Flash applications. <br /><br />
            </p>
            <p>
                <strong>Using pixel tags, web beacons, clear GIFs, or other similar technologies:</strong>
                These may be used in connection with some Service pages and HTML-formatted email messages to, among
                other things, track the actions of users and email recipients, measure the success of our marketing
                campaigns, and compile statistics about Service usage.
            </p>

            <p>
                <strong>Interest-based advertising:</strong>
                We may use third-party advertising companies to serve advertisements regarding goods and services that
                may be of interest to you when you access and use the Service and other online services, based on
                information relating to your access to and use of the Service and other online services on any of your
                devices. To do so, these companies may place or recognize a unique cookie on your browser (including
                through the use of pixel tags). They may also use these technologies, along with information they
                collect about your online use, to recognize you across the devices you use, such as a mobile phone and a
                laptop.<br /><br />
                If you would like more information about this practice, and to learn how to opt out of it in desktop and
                mobile browsers on the particular device on which you are accessing this Privacy Policy, please visit
                <a target="_blank" href="http://optout.aboutads.info/?c=2&lang=EN" class="link"
                    data-dom-id="http://optout.aboutads.info/?c=2&lang=EN" data-event-group="footer">
                    http://optout.aboutads.info/#/
                </a>
                and
                <a target="_blank" href="http://optout.networkadvertising.org/?c=1" class="link"
                    data-dom-id="http://optout.networkadvertising.org/?c=1" data-event-group="footer">
                    http://optout.networkadvertising.org/#/
                </a>
                .
            </p>
            <p>
                <strong>IP address:</strong>
                Your IP address is a number that is automatically assigned to your computer by your Internet Service
                Provider. An IP address is identified and logged automatically in our server log files whenever a user
                visits the Service, along with the time of the visit and the pages visited. Collecting IP addresses is
                standard practice and is done automatically by many online services. We use IP addresses for purposes
                such as calculating Service usage levels, diagnosing server problems, and administering the Service. We
                may also derive your approximate location from your IP address.
            </p>

            <p>
                <strong>Device Information:</strong>
                If you access the Services through your mobile device, We may collect information about your mobile
                device, such as a unique device identifier, to understand how you use the Service.
            </p>
        </div>

        <p class="sub-title">HOW WE USE AND DISCLOSE INFORMATION </p>

        <p>We use and disclose information you provide to us as described to you in the Collection Statement. Please see
            the section entitled “Choices and Access,” below, to learn how you may opt out of certain of our uses and
            disclosures.<br /><br />
            Where required by applicable law, we will obtain your consent to our use of your personal information at the
            point of information collection. We may also use information from or about you as necessary to perform a
            contract, to comply with a legal obligation or for our legitimate business interests. We may also rely on
            other legal bases, specifically for:
        </p>
        <ul>
        <p class="sub-title">
            <li class="list-color">Providing the functionality of the Service and fulfilling your requests.</li>
        </p>
         </ul>

        <ul class="list-style" style="list-style-type:circle">
            <li>
                to provide the functionality of the Service to you and providing you with related customer service;
            </li>
            <li>
                to respond to your inquiries and fulfill your requests, such as to send you documents you request or
                email alerts; </li>
            <li>
                to send you important information regarding our relationship with you or regarding the Service, changes
                to our terms, conditions, and policies and/or other administrative information. </li>
        </ul>
        <p>
            We will engage in these activities to manage our contractual relationship with you and/or to comply with a
            legal obligation.
        </p>
        <ul>
        <p class="sub-title">
            <li class="list-color">Accomplishing our business purposes.</li>
        </p>
        </ul>
        
        <ul class="list-style" style="list-style-type: circle">
            <li>
                for data analysis, for example, to improve the efficiency of the Service;
            </li>
            <li>
                for audits, to verify that our internal processes function as intended and are compliant with legal,
                regulatory, or contractual requirements;
            </li>
            <li>
                for fraud and security monitoring purposes, for example, to detect and prevent cyberattacks or attempts
                to commit identity theft;
            </li>
            <li>
                for developing new products and services;
            </li>
            <li>
                for enhancing, improving or modifying our website or products and services;
            </li>
            <li>
                for identifying Service usage trends, for example, understanding which parts of our Service are of most
                interest to users; and
            </li>
            <li>
                for determining the effectiveness of our promotional campaigns, so that we can adapt our campaigns to
                the needs and interests of our users.
            </li>
        </ul>
        <p>
            We will engage in these activities to manage our contractual relationship with you, to comply with a legal
            obligation, and/or because we have a legitimate interest.
        </p>

      <ul>
        <p class="sub-title">
            <li class="list-color">Analysis of Personal Information to provide personalized services.</li>
        </p>
      </ul>
 
        <ul class="list-style" style="list-style-type: circle">
            <li>
                to better understand you, so that we can personalize our interactions with you and provide you with
                information and/or offers tailored to your interests; </li>
            <li>
                to better understand your preferences so that we can deliver content via the Service that we believe
                will be relevant and interesting to you. </li>
        </ul>

        <p>
            We will provide personalized services either with your consent or because we have a legitimate interest.
        </p>
        <p>We also disclose information collected through the Service: </p>
        <ul class="list-style">
            <li class="section"> to our affiliates for the purposes described in this Privacy Policy.  A list of our
                affiliates is available at
                <a target="_blank" href="https://johnsonandjohnson.gcs-web.com/financial-information/sec-filings"
                    class="link" data-dom-id="https://johnsonandjohnson.gcs-web.com/financial-information/sec-filings"
                    data-event-group="footer">
                    http://www.investor.jnj.com/sec.cfm
                </a>
                (click on the link for Form 10K, Exhibit 21, under “SEC Filings”). <b>Medical Device Business Services,
                    Inc</b> . is the party responsible for the management of the jointly-used Personal Information;
            </li>

            <li class="section">to our third-party partners with whom we have existing business relationships;
            </li>

            <li class="section">to our third-party service providers who provide services such as website hosting and
                moderating, mobile application hosting, data analysis, payment processing, order fulfillment,
                infrastructure provision, IT services, customer service, email and direct mail delivery services,
                auditing, and other services, in order to enable them to provide services; and
            </li>

            <li class="section">as permitted by applicable law, to a third party in the event of any reorganization,
                merger, sale, joint venture, assignment, transfer, or other disposition of all or any portion of our
                business, assets, or stock (including in connection with any bankruptcy or similar proceedings).
            </li>
        </ul>

        <p>
            In addition, we may use and disclose your information as we believe to be necessary or appropriate: (a) to
            comply with legal process or applicable law, which may include laws outside your country of residence; (b)
            as permitted by applicable law to respond to requests from public and government authorities, which may
            include authorities outside your country of residence; (c) to enforce our terms and conditions; and (d) to
            protect our rights, privacy, safety, or property, and/or that of our affiliates, you, or others. We may also
            use and disclose your information in other ways, after obtaining your consent to do so. </p>
        <p>
            We may use and disclose information we collect automatically as described above, under “Automatic
            Information Collection and Use.”
            In addition, where allowed by applicable law, we may use and disclose information that is not in personally
            identifiable form for any purpose. If we combine information that is not in personally identifiable form
            with information that is identifiable (such as combining your name with your geographical location), we will
            treat the combined information as personal information as long as it is combined.
        </p>

        <p class="sub-title">LOCALLY STORED DATA </p>

        <p>Certain information, including information you may consider personal to you, may be stored locally on your
            device, including any mobile device. Please consult with your device’s documentation on how to manage local
            storage and how to apply appropriate security controls to the device for the protection of such information.
            Information or data that is stored on the device is your responsibility and may not be retrievable in the
            event that your device is lost or inoperable. </p>

        <p class="sub-title">CHOICES AND ACCESS </p>

        <p><b>Your choices regarding our use and disclosure of your personal information </b></p>

        <p>We will not use your information for marketing purposes. We may send you important transactional and
            administrative messages, from which you cannot opt out. </p>

        <p><b>How you can access, change, or delete your personal information </b></p>

        <p><b>If you would like to review, correct, update, restrict, or delete your personal information, or if you
                would like to request to receive an electronic copy of your personal information for purposes of
                transmitting it to another company (to the extent these rights are provided to you by applicable law),
                please contact us via e-mail with your request to <a
                    href="mailto:info@velysinsights.com">info&#64;velysinsights.com</a>. We will respond to your request
                as
                soon as reasonably practicable and no later than one month after receipt. If circumstances cause any
                delay in our response, you will be promptly notified and provided a date for our response. </b></p>

        <p class="sub-title">CROSS-BORDER TRANSFER</p>
        <p>Your personal information may be stored and processed in any country where we have facilities or service
            providers, and by using our Service or by providing consent to us (where required by law), your information
            may be transferred to countries outside of your country of residence, including to the United States, which
            may provide for different data protection rules than in your country. Appropriate contractual and other
            measures are in place to protect personal information when it is transferred to our affiliates or third
            parties in other countries. </p>

        <p class="sub-title">SECURITY</p>
        <p>We seek to use reasonable organizational, technical, and administrative measures designed to protect personal
            information under our control. Unfortunately, no data transmission over the Internet or data storage system
            can be guaranteed to be 100% secure. If you have reason to believe that your interaction with us is no
            longer secure (for example, if you feel that the security of any account you have with us has been
            compromised), please immediately notify us in accordance with the <i>“Contacting Us”</i> section below. </p>

        <p class="sub-title">RETENTION PERIOD </p>
        <p>We will retain your personal information for as long as needed or permitted in light of the purpose(s) for
            which it was obtained. The criteria used to determine our retention periods include: (i) the length of time
            we have an ongoing relationship with you and provide the Service to you; (ii) whether there is a legal
            obligation to which we are subject; and (iii) whether retention is advisable in light of our legal position
            (such as in regard to applicable statutes of limitations, litigation, or regulatory investigations). </p>

        <p class="sub-title">THIRD PARTY SITES AND SERVICES </p>
        <p>This Privacy Policy does not address, and we are not responsible for, the privacy, information, or other
            practices of any third parties, including any third party operating any site or online service (including,
            without limitation, any application) that is available through this Service or to which this Service
            contains a link. The availability of, or inclusion of a link to, any such site or property on the Service
            does not imply endorsement of it by us or by our affiliates. </p>

        <p class="sub-title">CONTACTING US </p>

        <p><b>Medical Device Business Services, Inc.</b> located at 700 Orthopaedic Dr., Warsaw, IN 46582 is the company
            responsible for collection, use, and disclosure of personal information under this Privacy Policy. </p>
        <p>If you have any questions about this Privacy Policy, please contact us via e-mail with your request to <a
                href="mailto:info@velysinsights.com">info&#64;velysinsights.com</a>, or please write to the following
            address: </p>

        <p class="contact-us">Medical Device Business Services, Inc.<br />

            700 Orthopaedic Dr. <br />

            Warsaw, IN 46582 </p>

        <p class="sub-title">UPDATES TO THIS PRIVACY POLICY </p>
        <p>We may change this Privacy Policy. Any changes to this Privacy Policy will
            become effective when we post the revised Privacy Policy on the Service.
            Your use of the Service following these changes means that you accept the
            revised Privacy Policy.
            We recommend that you regularly review the Privacy Policy when you visit
            the Service. This policy was last updated on
            <span><b> March 02, 2023</b></span>
        </p>
        <p class="sub-title" style="text-align: center;">US SUPPLEMENTAL PRIVACY NOTICE
            <br><span style="text-align: center;">
                <p>[Last Updated December 14, 2022]</p>
            </span><br>
        </p>
        <p>This US Supplemental Privacy Notice (“Supplemental Notice”) applies only to information collected about
            California, Colorado, Virginia, Utah, and Connecticut consumers. It provides information required under the
            following laws, (collectively, “US State Privacy Laws”):
        </p>
        <ul>
            <li>California Consumer Privacy Act of 2018 and California Privacy Rights Act of 2020 (collectively, the
                “CPRA”)
            </li>
            <li>Colorado Privacy Act of 2021 (the “CPA”)</li>
            <li>Connecticut Data Privacy Act (“CTDPA”) </li>
            <li>Utah Consumer Privacy Act of 2022 (the “UCPA”)</li>
            <li>Virginia Consumer Data Protection Act of 2021 (the “VCDPA”) </li>
        </ul>
        <p>We also provide information collected about Nevada consumers under the heading “Privacy Notice for Nevada
            Residents” at the end of this Supplemental Notice. The other portions of this Supplemental Notice do not
            apply to Nevada consumers.</p>
        <p>This Supplemental Notice describes <b>Medical Device Business Services, Inc.</b> (“we,” “us,” “our”)
            practices regarding the
            collection, use, and disclosure of Personal Information and provides instructions for submitting data
            subject requests. This Supplemental Notice is parallel in scope to our Global Privacy Policy and should be
            read in conjunction with our Global Privacy Policy. </p>
        <p> Some portions of this Supplemental Notice apply only to consumers of particular states. In those instances,
            we have indicated that such language applies only to those consumers.</p>
        <p class="sub-title"> A.&nbsp;&nbsp;Definitions</p>
        <ul>
            <li><u>“Personal Information”</u> means information that identifies, relates to, describes, is reasonably
                capable of being associated with, or could reasonably be linked, directly or indirectly, with a
                particular consumer or household. Personal Information includes “personal data” as that term is defined
                in the applicable US State Privacy Law. Personal Information also includes “Sensitive Personal
                Information,” as defined below, except where otherwise noted.</li>
            <li><u>“Sensitive Personal Information”</u> means Personal Information that reveals a consumer’s social
                security, driver’s license, state identification card, or passport number; account log-in, financial
                account number, debit card number, or credit card number in combination with any required security or
                access code, password, or credentials allowing access to an account; precise geolocation; racial or
                ethnic origin, religious beliefs, or union membership; contents of email or text messages; and genetic
                data. Sensitive Personal Information also includes processing of biometric information for the purpose
                of uniquely identifying a consumer and Personal Information collected and analyzed concerning a
                consumer’s health, sex life, or sexual orientation. Sensitive Personal Information also includes
                “sensitive data” as that term is defined in the applicable US State Privacy Law.</li>
            <li><u>“Third Party”</u> has the meanings afforded to it in the applicable US State Privacy Law.</li>
            <li><u>“Vendor”</u> means a service provider, contractor, or processor as those terms are defined in the
                applicable US State Privacy Law.</li>
        </ul>
        <p>To the extent other terms used in this Supplemental Notice are defined terms under the applicable US State
            Privacy Law, they shall have the meanings afforded to them in those statutes, whether or not capitalized
            herein. As there are some variations between such definitions in each of the state statutes, the definitions
            applicable to you are those provided in the statute for the state in which you are a consumer. For example,
            if you are a Virginia consumer, terms used in this Supplemental Notice that are defined terms in the VCDPA
            shall have the meanings afforded to them in the VCDPA as this Supplemental Notice applies to you.</p>
        <p class="sub-title"> B.&nbsp;&nbsp;Collection & Processing of Personal Information</p>
        <p>We, and our Vendors, may have collected and processed the following categories of Personal Information about
            you in the preceding 12 months:</p>
        <div class="personal-inform">
            <ul class="list-style" style="list-style-type: decimal">
                <li>Identifiers, such as name, alias, online identifiers, account name, physical characteristics
                    or description;</li>
                <li>Contact and financial information, including phone number, address, email address, financial
                    information, medical information, health insurance information;</li>
                <li>Characteristics of protected classifications under state or federal law, such as age, gender,
                    race, physical or mental health conditions, and marital status;</li>
                <li>Commercial information, such as transaction information, payment information, tax withholding
                    information and purchase history;</li>
                <li>Biometric information;</li>
                <li>Internet or other electronic network activity information, such as browsing history, search
                    history and interactions with our websites or advertisements;</li>
                <li>Geolocation data, such as device location;</li>
                <li>Audio, electronic, visual and similar information, such as call and video recordings;</li>
                <li>Professional or employment-related information, such as specialty, education history,
                    professional qualifications, work history and prior employer;</li>
                <li>Inferences drawn from any of the Personal Information listed above to create a profile or
                    summary about, for example, an individual’s preferences and characteristics; and</li>
                <li>Sensitive personal information, including:</li>
            </ul>
            <div class="privacy-word">
                <div class="text-space">
                    <p>a. Personal Information that reveals:</p>
                    <div class="text-inform">
                        <ul class="list-style" style="list-style-type: lower-roman">
                            <li>Social security, driver’s license, state identification card, or passport number;</li>
                            <li>Account log-in, financial account number, debit card number, or credit card number in
                                combination with any required security or access code, password, or credentials for
                                allowing
                                access to an account;</li>
                            <li>Precise geolocation;</li>
                            <li>Racial or ethnic origin, religious or philosophical beliefs, or union membership;</li>
                            <li>Genetic data.</li>
                        </ul>
                    </div>
                    <p>b. Biometric data processed for the purpose of uniquely identifying a consumer;</p>
                    <p>c. Personal Information collected and analyzed concerning a consumer’s health; and</p>
                    <p>d. Personal Information collected and analyzed concerning a consumer’s sex life or sexual
                        orientation.</p>
                </div>
            </div>
        </div>
        <p><b>Retention of Personal Information.</b> We retain your Personal Information for the period reasonably
            necessary to provide goods and services to you and for the period reasonably necessary to support our
            business operational purposes listed in Section E.</p>
        <p class="sub-title"> C.&nbsp;&nbsp;Categories of Personal Information We Disclose to Vendors & Third Parties
        </p>
        <p>We may disclose the following categories of Personal Information to Vendors and Third Parties:</p>
        <div class="personal-inform">
            <ul class="list-style" style="list-style-type: decimal">
                <li>Identifiers, such as name, alias, online identifiers, account name, physical characteristics
                    or description;</li>
                <li>Contact and financial information, including phone number, address, email address, financial
                    information, medical information, health insurance information;</li>
                <li>Characteristics of protected classifications under state or federal law, such as age, gender,
                    race, physical or mental health conditions, and marital status;</li>
                <li>Commercial information, such as transaction information, payment information, tax withholding
                    information and purchase history;</li>
                <li>Biometric information;</li>
                <li>Internet or other electronic network activity information, such as browsing history, search
                    history and interactions with our websites or advertisements;</li>
                <li>Geolocation data, such as device location;</li>
                <li>Audio, electronic, visual and similar information, such as call and video recordings;</li>
                <li>Professional or employment-related information, such as specialty, education history,
                    professional qualifications, work history and prior employer;</li>
                <li>Inferences drawn from any of the Personal Information listed above to create a profile or
                    summary about, for example, an individual’s preferences and characteristics; and</li>
                <li>Sensitive personal information, including:</li>
            </ul>
            <div class="privacy-word">
                <div class="text-space">
                    <p>a. Personal Information that reveals:</p>
                    <div class="text-inform">
                        <ul class="list-style" style="list-style-type: lower-roman">
                            <li>Social security, driver’s license, state identification card, or passport number;</li>
                            <li>Account log-in, financial account number, debit card number, or credit card number in
                                combination with any required security or access code, password, or
                                credentials for allowing access to an account;</li>
                            <li> Precise geolocation;</li>
                            <li>Racial or ethnic origin, religious or philosophical beliefs, or union membership;</li>
                            <li>Genetic data.</li>
                        </ul>
                    </div>
                    <p>b. Biometric data processed for the purpose of uniquely identifying a consumer;</p>
                    <p>c. Personal Information collected and analyzed concerning a consumer’s health; and</p>
                    <p>d. Personal Information collected and analyzed concerning a consumer’s sex life or sexual
                        orientation.</p>
                </div>
            </div>
        </div>
        <p><b>Disclosure for California Consumers:</b> Unless specifically stated, we have not sold or shared Personal
            Information about California consumers to third parties for their own use in the past twelve months.
            Relatedly, we do not have actual knowledge that we sell or share Personal Information of California
            consumers under 16 years of age. However, we may share your personal information with our affiliates and
            trusted partners in arrangements that may meet the broad definition of “sale” or “share” under California
            law. In these arrangements, use of the information we share is limited by policies, contracts, or similar
            restrictions.</p>
        <p>For purposes of the CPRA, a “sale” is the disclosure of Personal Information to a Third Party for monetary or
            other valuable consideration, and a “share” is the disclosure of Personal Information to a Third Party for
            cross-context behavioral advertising, whether or not for monetary or other valuable consideration.</p>
        <p><b>Disclosure for Colorado, Virginia, Utah, and Connecticut Consumers:</b> Unless specifically stated, we do
            not sell or share Personal Information to Third Parties for their own use. However, we may share or process
            one or more of the above categories of personal information with our affiliates and trusted partners in
            arrangements for purposes of targeted advertising, as the terms “sell,” “share,” “process,” and “targeted
            advertising” are defined in the CPA, VCDPA, UCPA, and CTDPA. In these arrangements, use of the information
            we share is limited by policies, contracts or similar restrictions.</p>
        <p class="sub-title"> D.&nbsp;&nbsp;Sources from Which We Collect Personal Information</p>
        <p>We collect Personal Information directly from California, Colorado, Virginia, Utah, and Connecticut
            consumers, as well as from our affiliates, business partners, joint marketing partners, public databases,
            providers of demographic data, publications, professional organizations, social media platforms, caregivers,
            third party information providers, affiliates with whom you have a business relationship, service providers
            with which we have a contractual relationship and to which you have provided your personal information,
            cookies and other tracking technologies, and Vendors and Third Parties when they share the information with
            us. </p>
        <p class="sub-title"> E.&nbsp;&nbsp;Purposes for Processing Personal Information</p>
        <p>We, and our Vendors, collect and process the Personal Information (excluding Sensitive Personal Information)
            described in this Supplemental Notice to:</p>
        <ul>
            <li>Operate, manage, and maintain our business;</li>
            <li>Respond to your inquiries and to fulfill your requests;</li>
            <li>Send you important information regarding our relationship with you or regarding this website, changes to
                our terms, conditions, and policies and/or other administrative information;</li>
            <li>Conduct audits, to verify that our internal processes function as intended and are compliant with legal,
                regulatory, or contractual requirements;</li>
            <li>Prevent fraud or crime, and for risk and technical security monitoring purposes;</li>
            <li>Facilitate the development of new products and services;</li>
            <li>Enhance, improve or modify our website or products and services; </li>
            <li>Perform research, analytics and, data analysis;</li>
            <li>Determine the effectiveness of our promotional campaigns, so that we can adapt our campaigns to the
                needs and interests of our users;</li>
            <li>Personalize, advertise, and market our products and services; </li>
            <li>Comply with law, legal process, and internal policies; </li>
            <li>Maintain records;</li>
            <li>Exercise and defend legal claims; and</li>
            <li>Otherwise accomplish our business purposes and objectives.</li>
        </ul>
        <p>We, and our Vendors, collect and process the Sensitive Personal Information described in this Supplemental
            Notice for:</p>
        <ul>
            <li>Performing the services or providing the goods reasonably expected by an average consumer who requests
                those goods or services;</li>
            <li>Ensuring security and integrity to the extent the use of the consumer's Personal Information is
                reasonably necessary and proportionate for these purposes;</li>
            <li>Performing services on our behalf, including maintaining or servicing accounts, providing customer
                service, processing or fulfilling orders and transactions, verifying customer information, processing
                payments, providing financing, providing analytic services, providing storage, or providing similar
                services on our behalf;</li>
            <li>Undertaking activities to verify or maintain the quality or safety of a service or device that is owned,
                manufactured, manufactured for, or controlled by us, and to improve, upgrade, or enhance the service or
                device that is owned, manufactured, manufactured for, or controlled by us;</li>
            <li>Personalizing, advertising, and marketing our products and services; </li>
            <li>Conducting research, analytics, and data analysis; </li>
            <li>Performing accounting, audit, and other internal functions, such as internal investigations; </li>
            <li>Complying with law, legal process, and internal policies; </li>
            <li>Maintaining records;</li>
            <li>Exercising and defending legal claims; and</li>
            <li>Otherwise accomplishing our business purposes and objectives.</li>
        </ul>
        <p class="sub-title"> F.&nbsp;&nbsp;Categories of Entities to Whom We Disclose Personal Information</p>
        <ul>
            <li><b>Affiliates & Vendors.</b> We may disclose your Personal Information to our affiliates and Vendors for
                the purposes described in Section E of this US Annex. A list of our affiliates is available at
                https://johnsonandjohnson.gcs-web.com/financial-information/sec-filings (click on the link for Form 10K,
                Exhibit 21, under “SEC Filings”). Our Vendors provide us with services for our websites, as well as
                other products and services, such as web hosting and moderating, mobile application hosting, data
                analysis, payment processing, order fulfillment, customer service, infrastructure provision, technology
                services, email and direct mail delivery services, auditing, legal services, and other similar services.
                We grant our Vendors access to Personal Information only to the extent needed for them to perform their
                functions, and require them to protect the confidentiality and security of such information.</li>
            <li><b>Third Parties.</b> We may disclose your Personal Information to the following categories of Third
                Parties:</li>
            <ul style="list-style-type: circle">
                <li><b>At Your Direction.</b> We may disclose your Personal Information to any Third Party with your
                    consent or at your direction.
                </li>
                <li><b>Business Transfers or Assignments.</b>We may disclose your Personal Information to other entities
                    as reasonably necessary to facilitate a reorganization, merger, sale, joint venture or
                    collaboration, assignment, transfer, or other disposition of all or any portion of our business,
                    assets, or stock (including in connection with any bankruptcy or similar proceedings).</li>
                <li><b>Third Party Co-Branding and Co-Marketing Partners.</b> We may share your Personal Information
                    with our third party partners with whom we offer a co-branded or co-marketed promotion.</li>
                <li><b>Third Party Advertising Partners.</b> To improve the effectiveness of our communication with you
                    and our marketing campaigns, we may share our data with our third party advertising partners,
                    including social media, medical journals and publishers.</li>
                <li><b>Legal and Regulatory.</b> We may disclose your Personal Information to government authorities,
                    including regulatory agencies and courts, as reasonably necessary for our business operational
                    purposes, to assert and defend legal claims, and otherwise as permitted or required by law.</li>
            </ul>
        </ul>
        <p class="sub-title"> G.&nbsp;&nbsp;Data Subject Rights</p>
        <ul>
            <li><b>Exercising Data Subject Rights.</b> California, Colorado, Virginia Utah, and Connecticut consumers
                have certain rights with respect to the collection and use of their Personal Information. Those rights
                vary by state. As required by the CPRA, we provide detailed information below regarding the data subject
                rights available to California consumers. Colorado, Virginia, Utah, and Connecticut consumers have
                similar rights and can find more detail by referencing the applicable US State Privacy Law.</li>
        </ul>
        <p>You may exercise the data subject rights applicable to you under the applicable US State Privacy Law by <a
                target="_blank"
                href="https://privacyportal.onetrust.com/webform/96f23ee1-34e3-41d6-8d5a-07f0d554152b/659417d6-807f-4eb1-87a8-709ce6363e5c?website=velys.jnj.com"
                class="link">click here</a> or contacting us at <a href="tel:+18004581663">1-800-458-1663</a>. While we
            will make reasonable efforts to
            accommodate your request, we reserve the right to impose certain restrictions or requirements on your
            request, if allowed by or required by applicable law.
        </p>
        <p>Consumers in some states may also authorize an agent to make data subject requests on their behalf.</p>
        <ul>
            <li><b>Verification of Data Subject Requests.</b> We may ask you to provide information that will enable us
                to verify your identity in order to comply with your data subject request. In particular, when a
                California or Connecticut consumer authorizes an agent to make a request on their behalf, we may require
                the agent to provide proof of signed permission from the consumer to submit the request, or we may
                require the consumer to verify their own identity to us or confirm with us that they provided the agent
                with permission to submit the request. In some instances, we may decline to honor your request if an
                exception applies under applicable law. We will respond to your request consistent with applicable law.
            </li>
            <li><b>Non-Discrimination.</b> We will not discriminate against you for exercising your data subject rights.
                For example, we will not deny goods or services to you, or charge you different prices or rates, or
                provide a different level of quality for products or services as a result of you exercising your data
                subject rights.</li>
            <li><b>Appeals</b> To appeal our decision on your data subject requests, you may <a target="_blank"
                    href="https://privacyportal.onetrust.com/webform/96f23ee1-34e3-41d6-8d5a-07f0d554152b/926e6f82-2e1b-40a8-8f34-c0e226862f5a"
                    class="link">clicking here</a></li>
            <li> Please enclose a copy of or otherwise specifically reference our decision on your data subject request,
                so that we may adequately address your appeal. We will respond to your appeal in accordance with
                applicable law.</li>
            <li><b>Data Subject Rights Disclosure </b></li>
            <ul style="list-style-type: circle">
                <li><b>Right to Receive Information on Privacy Practices:</b> You have the right to receive the
                    following information at or before the point of collection:</li>
                <ul style="list-style-type: square;">
                    <li>The categories of Personal Information to be collected;</li>
                    <li>The purposes for which the categories of Personal Information are collected or used;</li>
                    <li>Whether or not that Personal Information is sold or shared;</li>
                    <li>If the business collects Sensitive Personal Information, the categories of Sensitive Personal
                        Information to be collected, the purposes for which it is collected or used, and whether that
                        information is sold or shared; and</li>
                    <li>The length of time the business intends to retain each category of Personal Information, or if
                        that is not possible, the criteria used to determine that period.</li>
                </ul>
                <p>We have provided such information in this Supplemental Notice, and you may request further
                    information about our privacy practices by
                    <a target="_blank"
                        href="https://privacyportal.onetrust.com/webform/96f23ee1-34e3-41d6-8d5a-07f0d554152b/659417d6-807f-4eb1-87a8-709ce6363e5c?website=velys.jnj.com"
                        class="link">click here</a> or contacting us at <a href="tel:+18004581663">1-800-458-1663</a>.
                </p>
                <li><b>Right to Deletion:</b> You may request that we delete any Personal Information about you that we
                    collected from you.</li>
                <li><b>Right to Correction:</b> You may request that we correct any inaccurate Personal Information we
                    maintain about you.</li>
                <li><b>Right to Know:</b> You may request that we provide you with the following information about how
                    we have handled your Personal Information in the 12 months preceding your request:</li>
                <ul style="list-style-type: square;">
                    <li>The categories of Personal Information we collected about you</li>
                    <li>The categories of sources from which we collected such Personal Information;</li>
                    <li>The business or commercial purpose for collecting Personal Information about you;</li>
                    <li>The categories of Personal Information about you that we shared or disclosed and the categories
                        of Third Parties with whom we shared or disclosed such Personal Information; and</li>
                    <li>The specific pieces of Personal Information we have collected about you.</li>
                </ul>
                <li><b>Right to Receive Information About Onward Disclosures:</b>Right to Receive Information About
                    Onward Disclosures: You may request that we disclose to you:</li>
                <ul style="list-style-type: square;">
                    <li>The categories of Personal Information that we have collected about you;</li>
                    <li>The categories of Personal Information that we have sold or shared about you and the categories
                        of Third Parties to whom the Personal Information was sold or shared; and</li>
                    <li>The categories of Personal Information we have disclosed about you for a business purpose and
                        the categories of persons to whom it was disclosed for a business purpose.</li>
                </ul>
                <li><b>Right to Non-Discrimination:</b> You have the right not to be discriminated against for
                    exercising your data subject rights. We will not discriminate against you for exercising your data
                    subject rights.</li>
                <li><b>Right to Restrict or Limit the Use of Sensitive Personal Information:</b> You have the right to
                    restrict the use and disclosure of sensitive personal information to certain purposes related to the
                    offering of goods or services as listed in the CPRA. To exercise this right, you or your authorized
                    representative may submit a request by clicking on the following link: <a target="_blank"
                        href="https://privacyportal.onetrust.com/webform/96f23ee1-34e3-41d6-8d5a-07f0d554152b/659417d6-807f-4eb1-87a8-709ce6363e5c?website=velys.jnj.com"
                        class="link">Limit the use of my sensitive personal information</a> Do Not Sell or Share My
                    Personal Information </li>
                <li><b>Right to Opt Out of Sharing, Disclosure, or Sale of Personal Information:</b> You have the right
                    to direct us to not share, disclose, or sell your personal information. To exercise this right, you
                    or your authorized representative may submit a request by clicking on the following link: <a
                        target="_blank"
                        href="https://privacyportal.onetrust.com/webform/96f23ee1-34e3-41d6-8d5a-07f0d554152b/659417d6-807f-4eb1-87a8-709ce6363e5c?website=velys.jnj.com"
                        class="link">Do Not Sell or Share My Personal Information </a></li>
            </ul>
        </ul>
        <p class="sub-title"> H.&nbsp;&nbsp;Other Disclosures</p>
        <ul>
            <li><b>California Residents Under Age 18.</b> If you are a resident of California under the age of 18 and a
                registered user of our website, you may ask us to remove content or data that you have posted to the
                website by <a target="_blank"
                    href="https://privacyportal.onetrust.com/webform/96f23ee1-34e3-41d6-8d5a-07f0d554152b/659417d6-807f-4eb1-87a8-709ce6363e5c?website=velys.jnj.com"
                    class="link"> Clicking here </a> contacting us at <a href="tel:+18004581663">1-800-458-1663</a>.
                Please note that your
                request does not ensure complete or comprehensive removal of the content or data, as, for example, some
                of your content or data may have been reposted by another user.</li>
            <li><b>Disclosure About Direct Marketing for California Residents.</b> California Civil Code § 1798.83
                permits California residents to annually request certain information regarding our disclosure of
                Personal Information to other entities for their direct marketing purposes in the preceding calendar
                year. We do not distribute your Personal Information to other entities for their own direct marketing
                purposes. </li>
            <li><b>Financial Incentives for California Consumers.</b> We offer various types of financial incentives in
                exchange for your personal information, such as coupons, discounts, promotions, loyalty points,
                sweepstakes, contests, surveys, and other exclusive offers for California consumers who sign up to
                receive our marketing emails or join our loyalty program. The amount and terms of such offers will be
                presented to you at the time of the offer. When you participate in a financial incentive, we collect
                personal information from you, such as identifiers (like your email address) and commercial information
                (like your purchase history). Solely for purposes of California privacy law, we reasonably estimate the
                value of the discount, coupon, or other financial incentive that we offer to you to be equal to or
                greater than the value we receive from a consumer’s personal information collected in connection with
                the offer. We reasonably estimate the value of a consumer’s data by estimating the expenses related to
                the provision of each incentive program per consumer. We incur a variety of expenses for providing
                financial incentives, such as administrative and technical expenses associated with maintaining a
                loyalty program and costs associated with discounts on purchases. Participation in our financial
                incentives is voluntary. You may opt into a financial incentive by following the sign-up or
                participation instructions provided, and you have the ability to opt out by unsubscribing from our
                emails or closing your loyalty member account. </li>
            <li><b>Changes to our Supplemental Notice.</b> We reserve the right to amend this Supplemental Notice at our
                discretion and at any time. When we make material changes to this Supplemental Notice, we will notify
                you by posting an updated Supplemental Notice on our website and listing the effective date of such
                updates.</li>
        </ul>
        <p class="sub-title" style="text-align: center;">PRIVACY NOTICE FOR NEVADA RESIDENTS
            <br><span style="text-align: center;">
                <p>Effective: [December 14, 2022]</p>
            </span><br>
        </p>
        <p>This Privacy Notice for Nevada Residents adds to the information contained in the Johnson & Johnson Global
            Privacy Policy, and applies only to Nevada residents (“You,” “your” or “consumer”). </p>
        <p><b>Personal Information Collection and Purposes of Use</b></p>
        <p>We collect certain personal information of Nevada consumers through our Internet websites or other online
            service. This information includes one or more of the following elements of personally identifiable
            information:</p>
        <ul style="list-style-type: number;">
            <li>A first and last name.</li>
            <li>A home or other physical address that includes the name of a street and the name of a city or town.
            </li>
            <li>An electronic mail address.</li>
            <li>A telephone number.</li>
            <li>A Social Security Number.</li>
            <li>An identifier that allows a specific person to be contacted either physically or online.</li>
            <li>Any other information concerning a person collected from the person through the Internet website or
                online service of the operator, and maintained by the operator in combination with an identifier in a
                form that makes the information personally identifiable.</li>
        </ul>
        <p>We collect this personal information for the following purposes: </p>
        <ul>
            <li>to respond to your inquiries and to fulfill your requests;</li>
            <li>to send you important information regarding our relationship with you or regarding this website, changes
                to our terms, conditions, and policies and/or other administrative information;</li>
            <li>for audits, to verify that our internal processes function as intended and are compliant with legal,
                regulatory, or contractual requirements;</li>
            <li>for fraud or crime prevention, and for technical security monitoring purposes;</li>
            <li>to facilitate the development of new products and services;</li>
            <li>to enhance, improve or modify our website or products and services; </li>
            <li>for data analysis that will allow us to understand website usage trends;</li>
            <li>to determine the effectiveness of our promotional campaigns, so that we can adapt our campaigns to the
                needs and interests of our users.</li>
            <li>to better understand you, so that we can personalize our interactions with you and provide you with
                information and/or offers tailored to your interests.</li>
        </ul>
        <p><b>Your Privacy Rights</b></p>
        <p><u>Right to access and/or correct your personal information, or opt out of sale of personal information</u>
        </p>
        <p>If you would like to review, correct, or update your personal information, you or your authorized
            representative may submit your request to <a target="_blank"
                href="https://privacyportal.onetrust.com/webform/96f23ee1-34e3-41d6-8d5a-07f0d554152b/659417d6-807f-4eb1-87a8-709ce6363e5c?website=velys.jnj.com"
                class="link"> US MedTech DSAR Webform </a>We will respond to your verified request as soon as reasonably
            practicable, but no later than sixty (60) days after receipt. If circumstances cause any delay in our
            response, you will be promptly notified and provided a date for our response. </p>
        <p>We generally do not disclose or share personal information for profit. Under Nevada law, you have the right
            to direct us to not sell or license your personal information to third parties. To exercise this right, if
            applicable, you or your authorized representative may submit a request to <a target="_blank"
                href="https://privacyportal.onetrust.com/webform/96f23ee1-34e3-41d6-8d5a-07f0d554152b/659417d6-807f-4eb1-87a8-709ce6363e5c?website=velys.jnj.com"
                class="link"> US MedTech DSAR Webform </a> We will respond to your verified request as soon as
            reasonably practicable, but no later than sixty (60) days after receipt. If circumstances cause any delay in
            our response, you will be promptly notified and provided a date for our response.</p>
    </div>


</section>